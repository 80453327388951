<template>
  <div class="container-fluid">
    <div class="submenu" v-if="!loading">
      <router-link :to="'/generator/' + type.folderName" :class="{ 'active': selectedType === type.folderName }" v-for="(type) in types" :key="type.folderName">
        {{  type.name }}
      </router-link>
    </div>
    
    <div class="page">

      <div v-if="loading" class="text-center">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div class="variants-wrapper" v-if="!loading">
        <template v-if="selectedType !== ''">
          <router-link :to="'/generator'"><button class="btn btn-default btn-back"><i class="la la-angle-left"></i> Back</button></router-link>
          <h2 v-if="!availableVariants.length">Nothing to see here...</h2>
          <div class="masonry" v-if="availableVariants.length">
            <div class="category" v-for="(variant, index) in availableVariants" v-bind:key="index">
              <div v-if="variant.patronOnly && !isPatron"
                title="This handout is available to everyone but non-patrons will see a watermark" class="tag tag-red">
                Watermarked</div>
              <div v-if="!variant.patronOnly && !isPatron" title="This handout is available to everyone"
                class="tag tag-green">Free</div>
              <router-link :to="'/generator/' + selectedType + '/' + variant.name"><img
                  :src="'/images/types/' + selectedType + '/' + variant.name + '.jpg'" :alt="selectedType + ' - ' + variant.name" loading="lazy"/></router-link>
            </div>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import generatorData from '@/models/generator.json'

export default {
  name: 'Generator',
  components: {
  },
  data: function () {
    return {
      loading: true,
      types: [],
      selectedType: '',
      selectedTypeName: '',
      availableVariants: []
    }
  },
  created() {
    const tempData = [...generatorData.data];
    this.types = tempData.sort((a, b) => a.folderName.toLowerCase() > b.folderName.toLowerCase() ? 1 : -1);
    
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].folderName === this.$route.params.type) {
        this.selectedType = this.types[i].folderName;
        this.selectedTypeName = this.types[i].name;
        this.availableVariants = this.types[i].variants;
        this.loading = false;
      }
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
button {
  img {
    margin-right: 5px;
  }
}

.submenu {
  position:sticky;
  top:0;
  z-index:100000;
  border-left:1px solid #333;

  a {
    background:#fff;
    display:inline-block;
    text-transform: capitalize;
    font-size:16px;
    font-weight:700;
    padding:5px 10px;
    text-align:center;
    color:#6D842F;
    border-right:1px solid #333;
    border-bottom:1px solid #333;

    &.active {
      background:#6D842F;
      color:#fff;
    }

    &:hover, &:focus {
      background:#6D842F;
      color:#fff;
      text-decoration:none;
    }
  }
}

.btn-back {
  margin-bottom:10px;
}

h2 {
  margin: 30px 0 30px 0;
}

.masonry {
  grid-template-rows: masonry;
  grid-template-columns: masonry;
}

.category {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .2));

  img {
    cursor: pointer;
    max-height: 300px;
  }

  &:hover {
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .2));
  }
}

.variants-wrapper {
  margin: 0 auto;
  position: relative;
  max-width: 100%;
}

.tag {
  position: absolute;
  bottom: 5px;
  background: #671818;
  color: #fff;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
}

.tag-green {
  background: #6D842F;
  left: calc(50% - 25px);
}

.tag-red {
  background: #671818;
  left: calc(50% - 45px);
}

</style>
